<template>
  <div
    v-if="
      getUser.role.slug === 'rh' ||
        getUser.role.slug === 'super-administrateur' ||
        getUser.role.slug === 'assistant-rh'
    "
  >
    <b-breadcrumb class="mb-2">
      <b-breadcrumb-item to="/work/providers/recommandations/business">
        Packages
      </b-breadcrumb-item>
      <b-breadcrumb-item active>
        Commandes
      </b-breadcrumb-item>
    </b-breadcrumb>
    <div
      v-if="isPageLoading"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 30vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem"
        class="mr-1"
      />
    </div>
    <div v-else>
      <b-row>
        <b-col
          cols="12"
          lg="4"
          md="5"
        >
          <b-row>
            <b-col
              cols="12"
              xl="12"
              lg="12"
              md="12"
            >
              <b-card class="p-2">
                <div class="text-center">
                  <div
                    v-if="isload"
                    class="mb-2"
                  >
                    <b-spinner small />
                  </div>
                  <div
                    v-else
                    class="mb-2 "
                  >
                    <feather-icon
                      icon="LoaderIcon"
                      class="text-white mr-1 cursor-pointer"
                      size="30"
                      @click="reloadPackage()"
                    />
                  </div>
                </div>
                <div class="profile-image-wrapper">
                  <div class="profile-image text-center p-1">
                    <b-avatar
                    v-if="userPackageAssign.user.is_company == true"
                      variant="light-primary"
                      :text="avatarText(userPackageAssign.user.company_name)"
                      class="badge-minimal"
                      :src="userPackageAssign.user.profile_image"
                      size="104px"
                      rounded
                    />

                    <b-avatar
                    v-else
                      variant="light-primary"
                      :text="avatarText(userPackageAssign.user.full_name)"
                      class="badge-minimal"
                      :src="userPackageAssign.user.profile_image"
                      size="104px"
                      rounded
                    />
                  </div>
                </div>
                <div class="text-center">
                  <h3 v-if="userPackageAssign.user.is_company == false">{{ userPackageAssign.user.full_name }}</h3>
                  <h3 v-else>{{ userPackageAssign.user.company_name }}</h3>
                  <h6 class="text-info">
                    {{ userPackageAssign.email }}
                  </h6>
                  <b-badge
                    class="profile-badge"
                    variant="light-success"
                  >
                    {{ userPackageAssign.user.phone_number }}
                  </b-badge>
                </div>


                <hr class="mb-2">

                <!-- follower projects rank -->
                <div>
                  <h6 class="text-info text-justify mb-1">
                    DETAILS
                  </h6>
                  <div class="d-flex justify-content-between align-items-center mb-1" v-if="userPackageAssign.user.is_company == true">
                    <div>
                      <h5 class="font-weight-bolder">
                        Entreprise:
                      </h5>
                    </div>
                    <div>
                      <h6 class="text-info">
                        {{ userPackageAssign.user.company_name }}
                      </h6>
                    </div>
                  </div>
                  
                  <div class="d-flex justify-content-between align-items-center mb-1" >
                      <div>
                        <h5 class="font-weight-bolder">
                          IFU:
                        </h5>
                      </div>
                      <div>
                        <h6 class="text-info">
                          {{ userPackageAssign.user.company_ifu == null ? "Non renseigné" : userPackageAssign.user.company_ifu }}
                        </h6>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-1" v-if="userPackageAssign.user.is_company">
                      <div>
                        <h5 class="font-weight-bolder">
                          Siège de l'entreprise:
                        </h5>
                      </div>
                      <div>
                        <h6 class="text-info">
                          {{ userPackageAssign.user.company_address }}
                        </h6>
                      </div>
                    </div>
                  <div class="d-flex justify-content-between align-items-center mb-1">
                    <div>
                      <h5 class="font-weight-bolder">
                        Téléphone:
                      </h5>
                    </div>
                    <div>
                      <h6 class="text-info">
                        {{ userPackageAssign.user.phone_number }}
                      </h6>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center mb-1">
                    <div>
                      <h5 class="font-weight-bolder">
                        Mail:
                      </h5>
                    </div>
                    <div>
                      <h6 class="text-info ml-2">
                        {{ userPackageAssign.user.email == null ? 'Non renseigné' : userPackageAssign.user.email }}
                      </h6>
                    </div>
                  </div>

                  <div v-if="getUser.role.slug !== 'assistant-rh'">
                  <div
                    class="d-flex justify-content-between align-items-center mb-1"
                  >
                    <div>
                      <h5 class="font-weight-bolder">
                        Commercial:
                      </h5>
                    </div>
                    <div>
                      <h6 class="text-info">
                        {{ userPackageAssign.ca !== null ? userPackageAssign.ca.full_name : "Non assigné" }}
                      </h6>
                    </div>
                  </div>
                </div>

                <div >
                  <div
                    class="d-flex justify-content-between align-items-center mb-1"
                  >
                    <div>
                      <h5 class="font-weight-bolder">
                        RH:
                      </h5>
                    </div>
                    <div>
                      <h6 class="text-info">
                        {{ userPackageAssign.rh !== null ? userPackageAssign.rh.full_name : "Non assigné" }}
                      </h6>
                    </div>
                  </div>

                  <div >
                <b-row>
                  <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mt-2"
                  >
                    <b-button
                    v-if="
                              getUser.role.slug === 'super-administrateur' ||
                                getUser.role.slug === 'rh'
                            "
                            :id="`assign${userPackageAssign.id}`"
                            variant="outline-success"
                            
                            
                            :disabled="userPackageAssign.is_closed == true || (userPackageAssign.assign_to != null && userPackageAssign.assign_to.id == getUser.id) "
                            @click="applyautoAssignmentRHAction()"
                    >
                      Me l'assigner
                    </b-button>
                  </b-col>

                  <b-col
                      cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-end mt-2"
                  >
                    <b-button
                      variant="outline-info"
                      :id="`assign${userPackageAssign.id}`"
                            v-b-modal.modal-order-assignment
                            @click="PackageToAssign = userPackageAssign"
                    >
                      Assigner ARH
                    </b-button>
                  </b-col>
                </b-row>
                
              </div>
                </div>
                  
                </div>

              <!--/ follower projects rank -->
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          lg="8"
          md="7"
        >
          <b-row>
            <b-col
              cols="12"
              xl="12"
              md="12"
            >
              <b-card>
                
                    <b-card>
                      <b-row>
                        <!-- Per Page -->

                        <b-col
                          cols="12"
                          md="6"
                          class="d-flex align-items-center justify-content-start mb-2"
                        >
                          <b-button
                            variant="primary"
                            @click="reLoadDataRecurringOrdersAction()"
                          >
                            <div v-if="isReLoadDataPackageOrders">
                              <span> Chargement ... </span>
                              <b-spinner small />
                            </div>
                            <span
                              v-else
                              class="text-nowrap font-medium-1"
                            >Actualiser</span>
                          </b-button>
                        </b-col>
                      </b-row>
                      <!-- <div :is-loading="isBusinessOrdersInPackageLoading">
                      <div
                        class="text-center text-info"
                        v-if="isBusinessOrdersInPackageLoading"
                      >
                        <b-spinner class="align-middle" /> &nbsp;
                        <span class="align-middle">Chargement... </span>
                      </div>
                    </div> -->

                      <b-row>
                        <b-col
                          cols="12"
                          md="12"
                          lg="12"
                          class="mb-md-0 mb-2"
                        >
                          <b-row>
                            <b-col
                              cols="12"
                              sm="6"
                              md="6"
                              lg="5"
                            >
                              <label for="">Service</label>
                              <v-select
                                id="role"
                                v-model="payloadFilter.service_id"
                                :reduce="(service) => service.id"
                                label="name"
                                :options="recurringServicesOption"
                                class="invoice-filter-select"
                              >
                                <template v-slot:no-options>
                                  <b-spinner
                                    v-if="isServicesRecurringWithoutPaginationLoading"
                                    style="width: 2.5rem; height: 2.5rem"
                                    class="align-middle text-info"
                                  />

                                  <span
                                    v-else
                                    class="mt-1 font-medium-1"
                                  >Aucun service
                                  </span>
                                </template>
                              </v-select>
                            </b-col>
                            <b-col
                              cols="12"
                              sm="6"
                              md="6"
                              lg="5"
                            >
                              <label for="">Statut</label>
                              <v-select
                                v-model="payloadFilter.status"
                                :options="statusOptions"
                                class="invoice-filter-select"
                                :reduce="(item) => item.value"
                              >
                                <template #selected-option="{ label }">
                                  <span class="text-truncate overflow-hidden">
                                    {{ label }}
                                  </span>
                                </template>
                              </v-select>
                            </b-col>

                            <b-col
                              cols="12"
                              sm="6"
                              md="2"
                              lg="2"
                              class="mt-2"
                            >
                              <b-button
                                variant="primary"
                                @click="applyfilterBusinessOrderInPackageAction()"
                              >
                                <div v-if="isReLoadFilterDataRecurringOrders">
                                  <span> Chargement ... </span>
                                  <b-spinner small />
                                </div>

                                <span
                                  v-else
                                  class="text-nowrap font-medium-1"
                                >Filtrer</span>
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <vue-good-table
                        class="mt-2"
                        :columns="columns"
                        :rows="command"
                        :rtl="direction"
                        :is-loading="isBusinessOrdersInPackageLoading"
                      >
                        <template slot="emptystate">
                          <div class="text-center text-info">
                            <span> {{ isBusinessOrdersInPackageLoading ? "" : "Liste vide" }}</span>
                          </div>
                        </template>

                        <template slot="loadingContent">
                          <div class="text-center text-info my-2">
                            <b-spinner class="align-middle text-info" />
                          </div>
                        </template>
                        <template
                          slot="table-row"
                          slot-scope="props"
                        >
                          <div
                            v-if="props.column.field == 'recurring_service'"
                            class="align-middle text-center"
                          >
                            <span class="font-small-5 text-info">
                              {{ props.row.recurring_service.name }}
                            </span>
                          </div>

                          <div
                            v-else-if="props.column.field == 'brut_salary'"
                            class="text-nowrap text-center align-middle"
                          >
                            <span
                              class="d-block align-middle text-info text-center font-small-5"
                            >{{ props.row.brut_salary }} FCFA</span>
                          </div>

                          <div
                            v-else-if="props.column.field == 'setStatus'"
                            class="align-middle text-center"
                          >
                            <b-badge
                              pill
                              :variant="`light-${resolveOrderStatusVariant(
                                props.row.status,
                                props.row.placement_cost_paid,
                                props.row.search_employee
                              )}`"
                              class="text-capitalize text-nowrap font-medium-1"
                            >
                              {{ props.row.setStatus }}
                            </b-badge>
                          </div>

                          <div
                            v-else-if="
                              props.column.field == 'action' &&
                                getUser.role.slug !== 'charge-de-clientele'
                            "
                            class="align-middle text-center"
                          >
                            <b-dropdown
                              variant="link"
                              toggle-class="text-decoration-none"
                              no-caret
                            >
                              <template v-slot:button-content>
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="25"
                                  class="text-info align-middle mr-25"
                                />
                              </template>
                              <b-dropdown-item
                                class="itemActions cursor-pointer"
                                @click="businessOrderDetail(props.row)"
                              >
                                <feather-icon
                                  :id="`details${props.row.id}`"
                                  icon="EyeIcon"
                                  class="text-info mr-1"
                                  size="25"
                                />
                                <span>Détails</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </template>
                      </vue-good-table>

                      <div class="d-flex justify-content-between mt-2 flex-wrap">
                        <b-button
                          :disabled="metaData.prev_page_url == null"
                          variant="outline-secondary"
                          @click="applyLoadMoreDirectOrderAction(metaData.prev_page_url)"
                        >
                          <span
                            class="text-white text-nowrap font-medium-2"
                          >Page précédente</span>
                        </b-button>

                        <div>
                          <span
                            class="text-white font-medium-2"
                          >{{ metaData.current_page }} sur
                            {{ metaData.last_page }} pages</span>
                        </div>
                        <div>
                          <span class="text-white font-medium-2">Total :{{ total }}</span>
                        </div>

                        <b-button
                          :disabled="metaData.next_page_url == null"
                          variant="outline-secondary"
                          @click="applyLoadMoreDirectOrderAction(metaData.next_page_url)"
                        >
                          <span
                            class="text-nowrap font-medium-1 text-white"
                          >Page suivante</span>
                        </b-button>
                      </div>
                    </b-card>

              </b-card>
            </b-col>
          </b-row>

          <!-- Order assignment Modal -->
          <!-- Order assignment Modal -->
          <b-modal
          id="modal-order-assignment"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Assignation des assistants Rh"
        >
          <validation-observer
            #default="{}"
            ref="orderAssignmentForm"
          >
            <!-- Form -->
            <b-form
              class="p-2"
              autocomplete="off"
              @reset.prevent="hideModal"
              @submit.prevent="applyPackageAssignment"
            >
              <b-form-group
                label="Liste des assistants RH"
                label-for="role"
              >
                <validation-provider
                  #default="{ errors }"
                  name="assistants RH"
                  rules="required"
                >
                  <b-form-select
                    v-if="
                      AssistantRHOptions.length === 1 &&
                        AssistantRHOptions[0].disabled
                    "
                    id="rh_id"
                    v-model="payloadAssignment.rh_id"
                    :options="AssistantRHOptions"
                  />
                  <v-select
                    v-else
                    id="role"
                    v-model="payloadAssignment.rh_id"
                    :reduce="(businessManager) => businessManager.id"
                    label="full_name"
                    :options="AssistantRHOptions"
                  >
                    <span
                      slot="no-options"
                      @click="$refs.select.open = false"
                    >
                      Chargement...
                    </span>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  :disabled="isOrderAssignmentLoading"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  <div v-if="isOrderAssignmentLoading">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span v-else>Assigner</span>
                </b-button>
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  @click="hideModalAssignment()"
                >
                  Annuler
                </b-button>
              </div>
            </b-form>
          </validation-observer>
          </b-modal>
          <b-modal
            id="modal-action-times"
            cancel-variant="outline-secondary"
            hide-footer
            centered
            no-close-on-backdrop
            no-close-on-esc
          >
            <b-row class="">
              <b-col
                md="6"
                cols="12"
              >
                <p class="card-text mb-25 font-medium-1">
                  Date de la commande:
                  <span class="text-warning">
                    {{ moment(currentOrder.created_at).locale("fr").format("llll") }}
                  </span>
                </p>
                <p class="card-text mb-25 font-medium-1">
                  Date d'affectation:
                  <span class="text-warning">
                    {{
                      !currentOrder.assign_to
                        ? "Non effectué"
                        : !currentOrder.auto_assignment
                          ? moment(currentOrder.assign_at).locale("fr").format("llll")
                          : "Auto assignation"
                    }}
                  </span>
                </p>
                <p class="card-text mb-25 font-medium-1">
                  Date du feedback:
                  <span class="text-warning">{{
                    currentOrder.feedback_make_at
                      ? moment(currentOrder.feedback_make_at).locale("fr").format("llll")
                      : "Non effectué"
                  }}</span>
                </p>
                <p class="card-text mb-25 font-medium-1">
                  Dernière recommandation:
                  <span class="text-warning">
                    {{
                      currentOrder.recommandation_make_at
                        ? moment(currentOrder.recommandation_make_at)
                          .locale("fr")
                          .format("llll")
                        : "Non effectué"
                    }}
                  </span>
                </p>
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <p class="card-text mb-25 font-medium-1">
                  Dernière proposition:
                  <span class="text-warning">
                    {{
                      currentOrder.proposition_make_at
                        ? moment(currentOrder.proposition_make_at)
                          .locale("fr")
                          .format("llll")
                        : "Non effectué"
                    }}
                  </span>
                </p>
                <p class="card-text mb-25 font-medium-1">
                  Date de déploiement:
                  <span class="text-warning">{{
                    currentOrder.employee_contract_started_date
                      ? moment(currentOrder.employee_contract_started_date)
                        .locale("fr")
                        .format("llll")
                      : "Non effectué"
                  }}</span>
                </p>
              </b-col>
            </b-row>
          </b-modal>
        </b-col>
      </b-row>
      <!-- Deploy Employee Modal -->
      <b-modal
        id="modal-update-deploiement-date"
        cancel-variant="outline-secondary"
        hide-footer
        centered
        no-close-on-backdrop
        no-close-on-esc
        title="Date de déploiement d'employé"
      >
        <validation-observer
          #default="{}"
          ref="deployEmployeeForm"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            autocomplete="off"
            @reset.prevent="hideModalUpdateDeployEmployeeDate()"
            @submit.prevent="applyUpdateDeploymentEmployee()"
          >
            <b-form-group
              label="Date de déploiement"
              label-for="role"
            >
              <validation-provider
                #default="{ errors }"
                name="date de déploiement"
                rules="required"
              >
                <flat-pickr
                  v-model="employee_contract_started_date"
                  class="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                :disabled="isUpdateDeployementDateLoading"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <div v-if="isUpdateDeployementDateLoading">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span v-else>Modifier la date</span>
              </b-button>
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                Annuler
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
    
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BAlert,
  BLink,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  BPagination,
  BTooltip,
  BSpinner,
  BFormSelect,
  BForm,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BBreadcrumb,
  BBreadcrumbItem,
  BTabs,
  BTab,
  BFormTextarea,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import router from '@/router'
import store from '@/store/index'
import NotAuthorized from '@/layouts/components/NotAuthorized.vue'
import UserViewUserInfoCard from '@/layouts/components/UserViewUserInfoCard.vue'

export default {
  name: 'OrderIndex',
  components: {
    BAlert,
    BLink,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    BFormSelect,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    flatPickr,
    vSelect,
    BDropdown,
    BDropdownItem,
    NotAuthorized,
    UserViewUserInfoCard,
    BTabs,
    BTab,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
  },

  data() {
    return {
      AssistantRHOptions: [],
      responseAuto: {},
      isTerminateContractLoading: false,
      terminatePayload: {
        date: '',
      },
      isUnpaidOrdersLoading: false,
      UnpaidOrders: [],
      isload: false,
      isPageLoading: true,
      clientPackage: null,
      avatarText,
      userPackageAssign: {},
      userPackage: {
        fullname: '',
        email: '',
        phone_number: '',
        company_address: '',
        company_name: '',
        is_activated: '',
        phone_number: '',
      },
      recurringServicesOption: [],
      isLoading: true,
      total: '',
      isUpdateDeployementDateLoading: false,
      tableItems: [],
      employee_contract_started_date: '',
      payloadAssignment: {
        rh_id:""
      },

      statusOptions: [
        {
          label: 'En attente d\'affectation',
          value: 0,
        },
        
        {
          label: 'Employé affecté',
          value: 1,
        },
        {
          label: 'Actif',
          value: 2,
        },
        {
          label: 'Résilié',
          value: -1,
        },
      ],

      managerOptions: [],

      customerOptions: [],

      payloadFilter: {
        service_id: '',
        status: '',
      },

      businessManagerOptions: [],

      isOrderAssignmentLoading: false,

      payload: {
        charge_daffaire_id: '',
      },

      autoAssignPayload: {
        rh_id: '',
      },

      PackageToAssign: {},
      currentOrder: {},
      required,
      rows1: [],
      command: [],
      isCommandLoading: true,
      columns: [
        {
          label: 'Service demandé',
          field: 'recurring_service',
          filterOptions: {
            enabled: false,
            placeholder: 'Rechercher par Service',
            filterFn(data, filterString) {
              return data.name.toLowerCase().includes(filterString.toLowerCase())
            },
          },
        },
        {
          label: 'Budget par eemployé',
          field: 'brut_salary',
          filterOptions: {
            enabled: false,
            placeholder: 'Rechercher par Client',
            filterFn(data, filterString) {
              return data.full_name.toLowerCase().includes(filterString.toLowerCase())
            },
          },
        },
        {
          label: 'Status',
          field: 'setStatus',
          filterOptions: {
            enabled: false,
            placeholder: 'Rechercher par Status',
            filterDropdownItems: [
              'En attente de feedback',
              'En attente de recommandation',
              'En attente de proposition',
              'Résilié',
              'Employé proposé',
              'Non Payé',
              'Contract émis',
              'Contract approuvé',
              'Actif',
              'Terminée',
            ],
            filterFn(data, filterString) {
              return data.toLowerCase() === filterString.toLowerCase()
            },
          },
        },

        {
          label: 'Action',
          field: 'action',
        },
      ],
      dir: false,
      isReLoadDataPackageOrders: false,
      isReLoadFilterDataRecurringOrders: false,

      // SUIVI CLIENT

      rows: [],
      config: { mode: 'range' },
      isMakeClientSuivi: false,
      isUpdateClientSuivi: false,
      total: '',
      customerOptions: [],
      isReLoadFilterOrdersActifs: false,

      isShowSendSuiviButton: false,
      isSendSuiviRapport: false,
      isClientSuivi: true,
      isUnPublishedSuivi: false,
      payloadSuivi: {
        suivis_date: '',
        resum: '',
        user_id: '',
      },
      payloadGetSuivi: {
        user_id: '',
      },
      updatePayload: {
        suivis_date: '',
        resum: '',
        suivi_id: '',
      },
      rows_clients_suivis: [],
      columnSuivi: [
        // { label: 'Nom du client', field: 'customer_full_name' },
        {
          label: 'Service fourni',
          field: 'employee_service',
        },

        {
          label: 'Date de déploiement',
          field: 'employee_contract_started_date',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      // payloadFilter: {
      //   user_id: '',
      // },
      columns_clients_suivis: [
        {
          label: 'Date du suivi',
          field: 'suivis_date',
        },
        // {
        //   label: 'Suivi effectué par',
        //   field: 'suivis_make_by',
        // },
        {
          label: 'Contenu du suivi',
          field: 'resum',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      isFilterSuivi: false,
      rangeDate: null,
      isSuivisLoading: false,
      other_resum: '',
      resumSuivi: '',
      selectedOption: 'others',
      resumOptions: [
        {
          value: 'Client injoignable',
          text: 'Client injoignable',
        },
        {
          value: 'RAS',
          text: 'Rien à signaler',
        },
        {
          value: 'others',
          text: 'Autres',
        },
      ],

    }
  },
  computed: {
    ...mapGetters('orders', ['getDirectOrders', 'getDirectOrderActif', 'getBusinessOrdersInPackage', 'getBusinessCurrentPackage']),
    ...mapGetters([
      'isBusinessOrdersInPackageLoading',
      'isDirectOrdersFilterLoading',
      'isCustomersWithoutPaginationLoading',
      'isDirectOrdersActifLoading',
      'isSuivisLoading',
    ]),
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('roles', ['getBusinessManagersList']),
    ...mapGetters('customers', ['getCustomersWithoutPagination']),

    ...mapGetters('services', [
      'getRecurringServices',
      'getRecurringServicesWithoutPagination',
    ]),
    ...mapGetters('suivis', ['getSuivis']),
    ...mapGetters(['isServicesRecurringWithoutPaginationLoading']),

    totale() {
      let sum = 0
      this.unpaidOrders.forEach(element => {
        sum += (element.recurring_service.placement_fee * element.number_of_employees)
      })
      return sum
    },
    remise() {
      let remiseSum = 0
      let totalEmployee = 0
      this.unpaidOrders.forEach(element => {
        totalEmployee += element.number_of_employees
      })
      if (totalEmployee >= 5 && totalEmployee <= 10) {
        remiseSum = (this.totale * 20) / 100
      }

      if (totalEmployee > 10) {
        remiseSum = (this.totale * 30) / 100
      }
      return remiseSum
    },
    netApayer() {
      let net = 0
      if (this.remise !== 0) {
        net = (this.totale - this.remise)
      } else {
        net = this.totale
      }
      return net
    },

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        last_page: '',
        current_page_url: '',
      }
      if (this.getBusinessOrdersInPackage != null) {
        meta.prev_page_url = this.getBusinessOrdersInPackage.prev_page_url
        meta.next_page_url = this.getBusinessOrdersInPackage.next_page_url
        meta.current_page = this.getBusinessOrdersInPackage.current_page
        meta.last_page = this.getBusinessOrdersInPackage.last_page
        meta.current_page_url = `${this.getBusinessOrdersInPackage.path}?page=${this.getBusinessOrdersInPackage.current_page}`
        this.total = this.getBusinessOrdersInPackage.total
      }
      return meta
    },
    metaDataSuivi() {
      const metaSuivi = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        last_page: '',
        current_page_url: '',
      }

      if (this.getSuivis != null) {
        metaSuivi.prev_page_url = this.getSuivis.prev_page_url
        metaSuivi.next_page_url = this.getSuivis.next_page_url
        metaSuivi.current_page = this.getSuivis.current_page
        metaSuivi.last_page = this.getSuivis.last_page
        metaSuivi.current_page_url = `${this.getSuivis.path}?page=${this.getSuivis.current_page}`
        this.totalSuivi = this.getSuivis.total
      }
      return metaSuivi
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  watch: {
    isServicesRecurringWithoutPaginationLoading(val) {
      if (val === false) {
        this.getRecurringServicesWithoutPagination.forEach(element => {
          this.recurringServicesOption.push({
            id: element.id,
            name: element.name,
          })
        })
      }
    },
    isSuivisLoading(val) {
      if (val === false) {
        this.rows = this.getSuivis.data
      }
    },
    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
            newValue[key] = ''
            this.packagesCommand()
            console.log(this.payloadFilter)
          }
        })
      },
      deep: true,
    },

    isBusinessOrdersInPackageLoading(val) {
      if (val === false) {
        this.command = this.customTab(this.getBusinessOrdersInPackage.data)
        console.log('pakage', this.getBusinessOrdersInPackage.data)
      }
    },
    // getBusinessOrdersInPackage(newValue, oldValue) {
    //   if (oldValue.data.length != 0 && oldValue.data.length != newValue.data.length) {
    //     this.rows = this.customTab(this.getBusinessOrdersInPackage.data)
    //   }
    // },
    rangeDate(val) {
      if (val != null && val.split('to').length === 2) {
        this.applyFilterSuiviAction(val)
      }
    },
    isBusinessOrdersInPackageLoading(val) {
      if (val === false) {
        this.rows = this.customTab(this.getBusinessOrdersInPackage.data)
        console.log('packaaage', this.getBusinessOrdersInPackage.data)
      }
    },
    isCustomersWithoutPaginationLoading(val) {
      if (val === false) {
        this.getCustomersWithoutPagination.forEach(element => {
          this.customerOptions.push({
            id: element.id,
            name: element.customer_full_name,
          })
        })
      }
    },
  },
  created() {
    this.applyGetUnpaidOrders()
    console.log('USER ID', this.getUser.id)
    console.log('SUIVIS', this.getRecurringServicesWithoutPagination)
    console.log('DDD', this.getBusinessOrdersInPackage)
    this.isLoading = true
    this.applygetAssistantRHListAction()
    this.applyGetBusinessManagersListAction()
    //this.reloadPackage()
    this.packagesCommand(this.$route.params.id)
    this.clientSuivisDetailsAction()
    if (this.getBusinessCurrentPackage == null) {
      this.userPackageAssign = JSON.parse(localStorage.getItem('clientBusinessPackage'))
    } else {
      this.userPackageAssign = this.getBusinessCurrentPackage
    }

    console.log('Get package in store', this.userPackageAssign)
    console.log('Assign package', this.userPackageAssign)
      ? (this.rows = this.customTab(this.getBusinessOrdersInPackage.data))
      : null

    if (this.isBusinessOrdersInPackageLoading === false) {
      this.rows = this.customTab(this.getBusinessOrdersInPackage.data)
    }
    this.getBusinessOrdersInPackage != null
      ? (this.rows = this.customTab(this.getBusinessOrdersInPackage.data))
      : null
    this.getSuivis !== null
      ? (this.rows = this.getSuivis.data)
      : null
  },
  mounted() {
    if (this.getRecurringServicesWithoutPagination.length != 0) {
      this.getRecurringServicesWithoutPagination.forEach(element => {
        this.recurringServicesOption.push({
          id: element.id,
          name: element.name,
        })
      })
    }
  },
  methods: {
    ...mapActions('packages', [
      'getDirectPackagesAction',
      'getPackageByIdAction',
      'loadMoreDirectOrderAction',
    ]),
    ...mapActions('roles', ['getBusinessManagersListAction','getAssistantRHListAction', 'getRHListAction']),
    ...mapActions('orders', [
      'getOrderInBusinessPackageAction',
      'getOrderBusinessPackageByIdAction',
      'assignAnBusinessOrderToARHAction',
      'getBusinessOrdersAction',
      'autoAssignmentRHAction',
      'assignAPackageToBusinessManagerAction',
      'assignAnOrderToBusinessManagerAction',
      'changeEmployeeDeploymentAction',
      'getDirectOrdersAction',
      'getDirectOrdersAssignedToBusinessManagerAction',
      'getUnpaidOrdersAction',
      // "loadMoreDirectOrderAction",
      'markDirectOrderAsFinishedAction',
      'markUnterminateContractAction',
      'loadMoreDirectOrdersActifAction',
      'getDirectOrdersActifAction',
       "filterBusinessOrderInPackageAction",
    ]),
    ...mapActions('professionals', ['confirmPlacementCostPaidAction']),
    ...mapActions('customers', ['getCustomersWithoutPaginationAction']),
    ...mapActions('suivis', [
      'markSuiviAction',
      'getSuiviAction',
      'updateSuiviAction',
      'filterSuiviAction',
      'getSuiviUnPublishedSuiviAction',
      'sendSuiviAction',
    ]),

    applygetAssistantRHListAction() {
      this.getAssistantRHListAction().then((response) => {
        if (response.data.length === 0) {
          this.AssistantRHOptions.push({
            value: "",
            disabled: true,
            text: "La liste des assistants RH est vide",
          });
        } else {
          response.data.forEach((element) => {
            this.AssistantRHOptions.push(element);
          });
        }
      });
    },

    applygetRHListAction() {
      this.getRHListAction().then((response) => {
        if (response.data.length === 0) {
          this.AssistantRHOptions.push({
            value: "",
            disabled: true,
            text: "La liste des assistants RH est vide",
          });
        } else {
          response.data.forEach((element) => {
            this.AssistantRHOptions.push(element);
          });
        }
      });
    },

    hideModalAssignment(){
      this.$bvModal.hide('modal-order-assignment')
      this.payloadAssignment= {
        rh_id: ""
      }
    },
    applyPackageAssignment() {
      this.isOrderAssignmentLoading = true
      this.assignAnBusinessOrderToARHAction({
        orderId: this.$route.params.id,
        payload: this.payloadAssignment,
      })
        .then(response => {
          this.userPackageAssign = this.getBusinessCurrentPackage
          console.log('aply assignment', response.data.message)
          // this.applyLoadMoreDirectOrderAction(this.metaData.current_page_url)
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
          console.log('RESPONSE ASSIGN', response)
          this.$store.commit('orders/SET_CURRENT_BUSINESS_PACKAGE', response.data)
          localStorage.setItem('clientBusinessPackage', JSON.stringify(response.data))
          this.reloadPackage()
          this.isOrderAssignmentLoading = false
          this.hideModalAssignment()
        })
        .catch(error => {
          this.isOrderAssignmentLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyOrderAssignment() {
      this.isOrderAssignmentLoading = true
      this.assignAnBusinessOrderToARHAction({
        orderId: this.$route.params.id,
        payload: this.payloadAssignment,
      })
        .then(async response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
          this.isOrderAssignmentLoading = false
          this.reLoadDataRecurringOrdersAction()
          this.hideModalAssignment()
          
        })
        .catch(error => {
          this.isOrderAssignmentLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyautoAssignmentRHAction() {
      this.autoAssignPayload = {
        rh_id: this.getUser.id,
      }
      this.$swal
        .fire({
          title: 'Auto assignment',
          text: 'Êtes-vous sûr de vouloir gérer ce package vous même ?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Non',
          allowOutsideClick: false,
          confirmButtonText: 'Oui',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
          preConfirm: () => this.autoAssignmentRHAction({
            packageId: this.$route.params.id,
            payload: this.autoAssignPayload,
          })
            .then(response => {
              this.responseAuto = response

              console.log("ResponseData", this.responseAuto)

            })
            .catch(error => {
              this.$swal.showValidationMessage(`${error.response.data.message}`)
            }),
        })
        .then(response => {
          this.userPackageAssign = this.getBusinessCurrentPackage
          console.log('aply assignment', this.getBusinessCurrentPackage)
          console.log('Autoassignement', response)
          if (response.isConfirmed) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Package ylomi direct assigné avec succès.',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
          console.log('RESPONSE ASSIGN', this.responseAuto)
          this.$store.commit('orders/SET_CURRENT_BUSINESS_PACKAGE', this.responseAuto)
          localStorage.setItem('clientBusinessPackage', JSON.stringify(this.responseAuto))
          this.applyGetBusinessPackagesAction()
          this.isOrderAssignmentLoading = false
        })
        .catch(error => {
          this.isOrderAssignmentLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },


    applyGetUnpaidOrders(orders) {
      this.isUnpaidOrdersLoading = true
      this.getUnpaidOrdersAction(this.$route.params.id)
        .then(response => {
          this.isUnpaidOrdersLoading = false
          this.unpaidOrders = response.data
          console.log(this.unpaidOrders)
        })
        .catch(error => {
          this.isUnpaidOrdersLoading = false
          console.log(error)
        })
    },

    reloadPackage() {
      this.isload = true
      this.getOrderBusinessPackageByIdAction(this.$route.params.id)
        .then(response => {
          this.isload = false
          this.userPackageAssign = response.data
          console.log( this.userPackageAssign)
          localStorage.removeItem('clientBusinessPackage')
          localStorage.setItem('clientBusinessPackage', JSON.stringify(this.userPackageAssign))
          this.$store.commit('orders/SET_CURRENT_BUSINESS_PACKAGE', this.userPackageAssign)
          
        })
        .catch(() => {
          console.log(error)
          this.isload = false
        })
    },

    hideModalTerminateContract() {
      this.terminatePayload = {
        date: '',
      }
      this.$bvModal.hide('modal-terminate-contract')
    },

    applyTerminateContractAction() {
      this.isTerminateContractLoading = true
      this.markDirectOrderAsFinishedAction({ packageId: this.$route.params.id, payload: this.terminatePayload })
        .then(response => {
          this.isTerminateContractLoading = false
          console.log(response)
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: response.message,
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
          this.hideModalTerminateContract(),
          this.getPackageByIdAction(this.$route.params.id)
            .then(response => {
              this.userPackageAssign = response.data
              localStorage.removeItem('clientBusinessPackage')
              localStorage.setItem('clientBusinessPackage', JSON.stringify(this.userPackageAssign))
              this.$store.commit('orders/SET_CURRENT__BUSINESS_PACKAGE', this.userPackageAssign)
              this.isload = false
            })
            .catch(() => {
              console.log(error)
            })
        })
        .catch(error => {
          this.isTerminateContractLoading = false
          console.log(error)
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    unterminatePackage() {
      this.$swal
        .fire({
          title: 'Rouvrir le dossier',
          text: 'Êtes-vous sûr de vouloir de réouvrir le dossier ?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Non',
          allowOutsideClick: false,
          confirmButtonText: 'Oui',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
          preConfirm: async () => await this.markUnterminateContractAction(this.$route.params.id)
            .then(response => {})
            .catch(error => {
              this.$swal.showValidationMessage(`${error.response.data.message}`)
            }),
        })
        .then(response => {
          if (response.isConfirmed) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Contrat réouvert avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.getPackageByIdAction(this.$route.params.id)
              .then(response => {
                this.userPackageAssign = response.data
                localStorage.removeItem('clientBusinessPackage')
                localStorage.setItem('clientBusinessPackage', JSON.stringify(this.userPackageAssign))
                this.$store.commit('orders/SET_CURRENT_BUSINESS_PACKAGE', this.userPackageAssign)
                this.isload = false
              })
              .catch(() => {
                console.log(error)
              })
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyMarkDirectOrderAsFinished() {
      this.$swal({
        title: 'Clôturer le dossier',
        text: 'Êtes-vous sûr de vouloir clôturer le dossier ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => await this.markDirectOrderAsFinishedAction(this.$route.params.id),
      })
        .then(response => {
          if (response.isConfirmed) {
            this.getDirectOrderAction(this.directOrder.id).then(async response => {
              this.directOrder = response.data
              this.directOrder = this.resolveStatusText(this.directOrder)
              this.$store.commit('orders/SET_DIRECT_ORDER', this.directOrder)
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: 'Contrat clôturé avec succès',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.getOrderInBusinessPackageAction()
            })
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyConfirmedPlacementCostPaidAction(customerPackage) {
      this.$swal
        .fire({
          title: 'Confirmer Frais de Placement',
          text: 'Êtes-vous sûr de vouloir valider les frais de placement ?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Non',
          allowOutsideClick: false,
          confirmButtonText: 'Oui',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-outline ml-1',
          },
          buttonsStyling: false,
          preConfirm: async () => {
            await this.confirmPlacementCostPaidAction(customerPackage.id)
            return await this.getOrderInBusinessPackageAction(this.$route.params.id)
          },
        })
        .then(response => {
          if (response.isConfirmed) {
            this.getOrderInBusinessPackageAction(this.$route.params.id).then(async response => {
            //  this.isReLoadDataPackageOrders = false;
              this.$store.commit('SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING', false)
              this.command = this.customTab(response.data)
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: 'Frais de placement confirmé avec succès',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.$bvModal.hide('modal-list-command-non-paye')
              this.getOrderInBusinessPackageAction()
            })
          }
        })
        .catch(error => {
          // this.isReLoadDataPackageOrders = false;
          this.$store.commit('SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING', false)
          this.isCommandLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    openCustomerContract(userPackageAssign) {
      this.$router.push({
        path: '/preview/pdf',
        query: {
          makeActionOnPage: false,
          actionType: null,
          isSigned: true,
          pdf: userPackageAssign.contract_file_url,
        },
      })
    },

    sendPlecementFeesPaymentLink() {
      window.open(`https://wa.me/${
        JSON.parse(localStorage.getItem('clientBusinessPackage')).user.phone_number
      }?text=Bonjour Mr/Mme!*${JSON.parse(localStorage.getItem('clientBusinessPackage')).user.full_name}* Merci de cliquer sur le lien suivant afin de payer les frais de placement pour vos commandes.
                    \n *https://ylomi.net/direct/${this.$route.params.id}/placement*`)
    },
    copyPlacementFeesPaymentLink() {
      navigator.clipboard
        .writeText(
          `Bonjour Mr/Mme!*${
            JSON.parse(localStorage.getItem('clientBusinessPackage')).user.full_name
          }*Merci de cliquer sur le lien suivant afin de payer les frais de placement pour vos commandes
     \n *https://ylomi.net/direct/${this.$route.params.id}/placement*`,
        )
        .then(() => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: 'Lien copié avec succès',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    newCommand() {
      this.$router.push(
        `/orders/business/new/command?search_employee=true&client_hide=true&packageId=${this.$route.params.id}`,
      )
    },
    newCommandGestion() {
      this.$router.push(
        `/orders/direct/new/commandgestion?search_employee=false&client_hide=true&packageId=${this.$route.params.id}`,
      )
    },
    goToClientWhatsaap() {
      window.open(
        `https://wa.me/${this.userPackageAssign.user.phone_number}?text=Bonjour Mr/Mme!*${this.userPackageAssign.user.full_name}*`,
      )
    },
    packagesCommand() {
      this.$store.commit('SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING', true)
      this.getOrderInBusinessPackageAction(this.$route.params.id)
        .then(response => {
          this.$store.commit('SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING', false)
          this.isPageLoading = false
          this.command = this.customTab(response.data.data)
          console.log('Les commandes ', this.command)
        })

        .catch(error => {
          this.isPageLoading = false
          this.$store.commit('SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING', false)
          console.log(error)
        })
    },
    applyUpdateDeploymentEmployee() {
      this.$bvModal.hide('modal-update-deploiement-date')
      this.isUpdateDeployementDateLoading = true
      this.$swal
        .fire({
          title: "Modifier la date de déploiement d'employé",
          text: 'Êtes-vous sûr de vouloir modifier la date de déploiement  cet employé ?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Non',
          allowOutsideClick: false,
          confirmButtonText: 'Oui',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
          preConfirm: () => this.changeEmployeeDeploymentAction({
            orderId: this.currentOrder.id,
            payload: { employee_deployment_date: this.employee_contract_started_date },
          })
            .then(response => {
              this.isUpdateDeployementDateLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
            .catch(error => {
              this.$swal.showValidationMessage(`${error.response.data.message}`)
            }),
        })
        .then(response => {
          if (response.isConfirmed) {
            this.hideModalUpdateDeployEmployeeDate()
            this.getOrderInBusinessPackageAction()
          } else {
            this.hideModalUpdateDeployEmployeeDate()
          }
        })
        .catch(error => {
          this.isUpdateDeployementDateLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    hideModalUpdateDeployEmployeeDate() {
      (this.employee_contract_started_date = ''),
      (this.isUpdateDeployementDateLoading = false)
      this.$bvModal.hide('modal-update-deploiement-date')
    },
    putRecurringOrdersInStoreAction(directOrder) {
      this.$store.commit('orders/SET_DIRECT_ORDER', directOrder)
      this.$router.push(
        `/orders/business/recurring/${directOrder.id}/details?packageId=${directOrder.package_id}&service=${directOrder.recurring_service_id}`,
      )
    },

    businessOrderDetail(directOrder){
      
      this.$router.push(
          `/business/recommandation/business/orders/${directOrder.id}/details?packageId=${directOrder.package_id}`,
        )
    },

    reLoadDataRecurringOrdersAction() {
      this.payloadFilter.service_id = ''
      this.payloadFilter.status = ''

      this.isReLoadDataPackageOrders = true
      this.$store.commit('SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING', true)
      this.command = []

      this.getOrderInBusinessPackageAction(this.$route.params.id)
        .then(response => {
          this.isReLoadDataPackageOrders = false
          this.$store.commit('SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING', false)
          console.log(response.data.data)
          this.command = this.customTab(response.data.data)
          console.log(this.command, "Liste des commandes")
        })
        .catch(error => {
          this.isReLoadDataPackageOrders = false
          this.$store.commit('SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING', false)
          console.log(error)
        })
      // this.getOrderInBusinessPackageAction()
      //   .then(response => {
      //     this.isReLoadDataRecurringOrders = false
      //     this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
      //   })
      //   .catch(error => {
      //     this.isReLoadDataRecurringOrders = false
      //     this.$store.commit('SET_IS_DIRECT_ORDERS_LOADING', false)
      //   })
    },

    applyLoadMoreDirectOrderAction(url) {
      this.$store.commit('SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING', true)
      this.rows = []
      this.loadMoreDirectOrderAction(url)
        .then(() => {
          this.$store.commit('SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING', false)
        })
    },
    showUpdateDeploiementDateModal(item) {
      console.log(item)
      this.$bvModal.show('modal-update-deploiement-date')
      this.employee_contract_started_date = item.employee_contract_started_date
      this.currentOrder = item
    },
    showActionTimesModal(item) {
      this.$bvModal.show('modal-action-times')
      this.currentOrder = item
    },

    applyGetDirectOrdersAssignedToBusinessManagerAction(id) {
      this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', true)
      this.getDirectOrdersAssignedToBusinessManagerAction(id)
        .then(() => {
          this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
        })
    },
    applyGetDirectOrderAction() {
      this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', true)
      this.getOrderInBusinessPackageAction()
        .then(() => {
          this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
        })
    },

    applyGetBusinessManagersListAction() {
      this.getBusinessManagersListAction().then(response => {
        if (response.data.length === 0) {
          this.businessManagerOptions.push({
            value: '',
            disabled: true,
            text: "La liste des chargés d'affaire est vide",
          })
        } else {
          response.data.forEach(element => {
            this.businessManagerOptions.push(element)
          })
        }
      })
    },
    applyGetBusinessPackagesAction() {
      this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', true)
      this.getBusinessOrdersAction()
        .then(response => {
          this.clientPackage = response.data
          this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', false)
          console.log('CLIENT', this.clientPackage)
        })
        .catch(() => {
          this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', false)
        })
    },

    hideModalAssigment() {
      this.$bvModal.hide('modal-order-assignment')
      this.payload = {
        rh_id: '',
      }
    },
   

    applyfilterBusinessOrderInPackageAction() {
      const allIsNUl = Object.values(this.payloadFilter).every(el => el === '')
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner au moins un champ du filtre.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.isReLoadFilterDataRecurringOrders = true
        this.$store.commit('SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING', true)
        // this.$store.commit('SET_IS_DIRECT_ORDERS_FILTER_LOADING', true)
        this.command = []

        this.filterBusinessOrderInPackageAction({
          id: this.$route.params.id,
          payload: this.payloadFilter,
        })
          .then(response => {
            this.isReLoadFilterDataRecurringOrders = false
            this.$store.commit('SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING', false)
            // this.$store.commit('SET_IS_DIRECT_ORDERS_FILTER_LOADING', false)
            this.command = this.customTab(response.data)
            this.total = this.command.length
          })
          .catch(error => {
            this.isReLoadFilterDataRecurringOrders = false
            this.$store.commit('SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING', false)

            console.log(error, "ERRREUR")

           /*  this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'danger',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'warning',
                },
              },
              {
                position: 'top-center',
              },
            ) */
          })
      }
    },

    openContract(data) {
      this.$router.push({
        path: '/preview/pdf',
        query: {
          makeActionOnPage: false,
          actionType: null,
          isSigned: true,
          pdf: data.contract_file_url,
        },
      })
    },

    sendContractByWhatsApp(data) {
      window.open(`https://wa.me/${data.user.phone_number}?text=Nos salutions!
Merci de cliquer sur le lien suivant afin de consulter le contract de prestation de votre commande ${data.recurring_service.name}. \n\n *${data.contract_file_url}*`)
    },

    // SUIVI CLIENTS

    applyFilterUserAction() {
      const allIsNUl = Object.values(this.payloadFilter).every(
        el => el === '',
      )
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner au moins un champ du filtre.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.isReLoadFilterOrdersActifs = true
        this.rows = []
        this.payloadFilter.type = 'SC'
        this.filterDirectOrdersActifAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterOrdersActifs = false
            console.log(response.data)
            this.rows = response.data
            this.total = this.rows.length
          })
          .catch(error => {
            this.isReLoadFilterOrdersActifs = false
          })
      }
    },
    hideModalClientSuivi() {
      this.isMakeClientSuivi = false
      this.$bvModal.hide('modal-make-client-suivi')
      this.resumSuivi = '',
      this.other_resum = '',
      this.payloadSuivi = {
        suivis_date: '',
        user_id: '',
        resum: '',
      }
    },
    hideModalUpdateSuiviSuivi() {
      this.$bvModal.hide('modal-update-client-suivi')
      this.updatePayload = {
        suivis_date: '',
        suivi_id: '',
        resum: '',
      }
    },
    updateClientSuiviAction(item) {
      this.updatePayload = {
        suivis_date: item.suivis_date,
        resum: item.resum,
        suivi_id: item.id,

      }
    },
    makeClientSuiviAction(item) {
      this.payload.employee_id = item.employee_id
      this.payload.user_id = item.user_id
    },
    clientSuivisDetailsAction() {
      this.isSuivisLoading = true
      this.isShowSendSuiviButton = false
      if (this.columns_clients_suivis.length > 4) {
        this.columns_clients_suivis.splice(2, 2)
      }
      if (this.getUser.role.slug === 'super-administrateur') {
        this.payloadGetSuivi.suivi_type = 'client'
      }
      this.payloadGetSuivi.user_id = JSON.parse(localStorage.getItem('clientBusinessPackage')).user.id
      this.getSuiviAction({
        payload: this.payloadGetSuivi,
      })
        .then(response => {
          this.isSuivisLoading = false
          this.rows_clients_suivis = response.data.data
        })
        .catch(error => {
          this.isSuivisLoading = false
          console.log(error)
        })
    },

    applySendSuiviRapportAction() {
      const payload = {}
      if (this.getUser.role.slug === 'super-administrateur') {
        payload.suivi_type = 'client'
      }
      this.isSendSuiviRapport = true
      this.sendSuiviAction(payload)
        .then(response => {
          console.log(response)
          this.$bvModal.hide('modal-client-suivis')
          this.isSendSuiviRapport = false
          if (response.data.length == 0) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Warning',
                  icon: 'CheckIcon',
                  text: response.message,
                  variant: 'warning',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: response.message,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.isSendSuiviRapport = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Warning',
                icon: 'CheckIcon',
                text: error.response.data.message,
                variant: 'warning',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    applyGetUnPublishedSuivi() {
      const payload = {}
      if (this.getUser.role.slug === 'super-administrateur') {
        payload.suivi_type = 'client'
      }
      this.isUnPublishedSuivi = true
      this.getSuiviUnPublishedSuiviAction(payload)
        .then(response => {
          this.isUnPublishedSuivi = false
          this.isSuivisLoading = false
          if (this.columns_clients_suivis.length == 4) {
            this.columns_clients_suivis.splice(
              2,
              0,
              {
                label: 'Client',
                field: 'client',
              },
              {
                label: 'Employé',
                field: 'employee',
              },
            )
          }
          if (response.data.length == 0) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Warning',
                  icon: 'CheckIcon',
                  text: response.message,
                  variant: 'warning',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.isShowSendSuiviButton = true
            this.rows_clients_suivis = response.data
            this.$bvModal.show('modal-client-suivis')
          }
        }).catch(error => {
          this.isUnPublishedSuivi = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Warning',
                icon: 'CheckIcon',
                text: error.response.data.message,
                variant: 'warning',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    applyFilterSuiviAction(val) {
      this.isShowSendSuiviButton = false
      const data = {
        start_date: val.split('to')[0],
        end_date: val.split('to')[1],
      }
      if (this.getUser.role.slug === 'super-administrateur') {
        data.suivi_type = 'client'
      }
      data.user_id = this.userPackageAssign.user.id
      data.type = 'SC'
      this.isFilterSuivi = true
      this.filterSuiviAction(data)
        .then(response => {
          this.isFilterSuivi = false
          this.isSuivisLoading = false
          if (this.columns_clients_suivis.length == 4) {
            this.columns_clients_suivis.splice(
              2,
              0,
              {
                label: 'Client',
                field: 'client',
              },
              {
                label: 'Employé',
                field: 'employee',
              },
            )
          }
          this.rows_clients_suivis = response.data
          this.total = this.rows_clients_suivis.length
          this.$bvModal.show('modal-client-suivis')
        }).catch(error => {
          this.isFilterSuivi = false
          if (error.response.status !== 422) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: 'Erreur lors du filtrage, Réessayer!',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
    },
    applyGetSuiviReloadAction() {
      this.isSuivisLoading = true
      this.$store.commit('SET_IS_SUIVIS_LOADING', true)
      this.payloadGetSuivi.method = 'GET'
      this.getSuiviAction({ payload: this.payloadGetSuivi })
        .then(response => {
          this.isSuivisLoading = false
          this.rangeDate = null
          this.$store.commit('SET_IS_SUIVIS_LOADING', false)
          this.rows_clients_suivis = response.data.data
          console.log(this.rows_clients_suivis)
        })
        .catch(error => {
          this.isSuivisLoading = false
          this.$store.commit('SET_IS_SUIVIS_LOADING', false)
          console.log(error)
        })
    },

    applyLoadMoreDirectOrdersActifActionAction(url) {
      this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', true)
      this.rows = []
      this.loadMoreDirectOrdersActifAction(url)
        .then(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', false)
        })
    },

    applyMakeSuiviSuivi() {
      this.payloadSuivi.user_id = this.userPackageAssign.user.id
      this.$refs.ClientSuivi.validate().then(success => {
        if (success) {
          this.isMakeClientSuivi = true
          if (this.getUser.role.slug === 'super-administrateur') {
            this.payloadSuivi.suivi_type = 'client'
          }
          if (this.resumSuivi === 'others') {
            this.payloadSuivi.resum = this.other_resum
          } else {
            this.payloadSuivi.resum = this.resumSuivi
          }
          this.markSuiviAction(this.payloadSuivi)
            .then(response => {
              this.clientSuivisDetailsAction(response.data.user_id)
              this.$bvModal.show('modal-client-suivis')

              this.applyLoadMoreDirectOrdersActifActionAction(
                this.metaData.current_page_url,
              )
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.hideModalClientSuivi()
            })
            .catch(error => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.isMakeClientSuivi = false
            })
        }
      })
    },
    applyupdateClientSuiviAction() {
      this.$refs.UpdateClientSuivi.validate().then(success => {
        if (success) {
          this.isUpdateClientSuivi = true
          //   if (this.updatePayload.resum === 'others') {
          //   this.updatePayload.resum =  this.updatePayload.resum
          // } else {
          //   this.updatePayload.resum = this.updatePayload.resum
          // }
          this.updateSuiviAction({
            payload: this.updatePayload,
            suiviId: this.updatePayload.suivi_id,
          })
            .then(response => {
              this.isUpdateClientSuivi = false
              this.payloadGetSuivi.suivi_type = 'client'
              this.payloadGetSuivi.user_id = response.data.user_id
              if (this.getUser.role.slug === 'super-administrateur') {
                this.payloadGetSuivi.suivi_type = 'client'
              }
              this.getSuiviAction({
                payload: this.payloadGetSuivi,
              }).then(response => {
                this.isSuivisLoading = false
                this.rows_clients_suivis = response.data.data
              })
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.hideModalUpdateSuiviSuivi()
            })
            .catch(error => {
              console.log(error)
              this.isUpdateClientSuivi = false
            })
        }
      })
    },

    resolveOrderStatusVariant: (status) => {
    if (status === 0) return 'warning'
    if (status === 1) return 'info'
    if (status === 2) return 'success'
    if (status === -1) return 'danger'
    return 'primary'
  },

    customTab(array) {
    const tab = []
    for (let index = 0; index < array.length; index++) {
      const element = array[index]
      if (
        element.status === 0
      ) {
        element.setStatus = 'En attente d\'affectation'
        tab.push(element)
        continue
      }

      if (element.status === 1) {
        element.setStatus = 'Employé affecté'
        tab.push(element)
        continue
      }

      if (
        element.status === 2
        
      ) {
        element.setStatus = 'Actif'
        tab.push(element)
        continue
      }

      if (
        element.status === -1
        
      ) {
        element.setStatus = 'Résilié'
        tab.push(element)
        continue
      }
      
    }
    return tab
  },

    /* customTab(array) {
      const tab = []
      for (let index = 0; index < array.length; index++) {
        const element = array[index]
        if (
          element.status === 0
          && element.placement_cost_paid === false
          && element.search_employee === true
        ) {
          element.setStatus = 'Non Payé'
          tab.push(element)
          continue
        }

        if (element.status === 0 && element.feedback === '') {
          element.setStatus = 'En attente de feedback'
          tab.push(element)
          continue
        }

        if (
          element.status === 0
          && element.feedback !== ''
          && element.recommandations_count === 0
        ) {
          element.setStatus = 'En attente de recommandation'
          tab.push(element)
          continue
        }

        if (
          element.status === 0
          && element.feedback != ''
          && element.propositions_count === 0
        ) {
          element.setStatus = 'En attente de proposition'
          tab.push(element)
          continue
        }
        if (element.status === -1) {
          element.setStatus = 'Résilié'
          tab.push(element)
          continue
        }
        if (element.status === 1) {
          element.setStatus = 'Employé proposé'
          tab.push(element)
          continue
        }
        if (element.status === 2) {
          element.setStatus = 'Contract émis'
          tab.push(element)
          continue
        }
        if (element.status === 3) {
          element.setStatus = 'Contract approuvé'
          tab.push(element)
          continue
        }
        if (element.status === 4) {
          element.setStatus = 'Actif'
          tab.push(element)
          continue
        }
        if (element.status === 5) {
          element.setStatus = 'Terminée'
          tab.push(element)
          continue
        }
      }
      return tab
    }, */
  },
}
</script>

<style>
i.icon-code {
  display: none !important;
}

.vgt-select {
  background: transparent !important;
  border-color: #404656 !important;
}

.itemActions :hover {
  background: transparent !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 200px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-good-table.scss";

  .v-list .v-list-item--active {
    background-color: green !important;
  }

  .v-list .v-list-item--active .v-list-item__title {
    color: #ffd54f !important;
  }
</style>
